import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Box from '@material-ui/core/Box'
import { withRouter } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import firebase from '../../firebase/firebase'
import Subscription from '../../components/common/Subscription'
import { withStyles } from '@material-ui/styles'
import StyledButton from '../../components/StyledButton'
import GrayTextTypography from '../../components/common/GrayTextTypography'

class Login extends React.Component {

  constructor(props) {
    super(props)

    this.state = { error: '' }
    this.handleGoogleLogin = this.handleGoogleLogin.bind(this)
    this.handleGoogleLoginStore = this.handleGoogleLoginStore.bind(this)
    this.handleTwitter = this.handleTwitter.bind(this)

    this.history = props.history
  }

  handleTwitter(e) {
    e.preventDefault()

    window.open('https://twitter.com/armatastools', '_blank')
  }

  async handleGoogleLogin(e) {
    if (e) {
      e.preventDefault()
    }

    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)

    try {
      await firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider())
    } catch (err) {
      this.setState({ error: err.message })
    }

  }

  async handleGoogleLoginStore(e) {
    if (e) {
      e.preventDefault()
    }

    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)

    try {
      await firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider())

      setTimeout(function () { //Start the timer
        this.history.push('/pricing')
      }.bind(this), 1000)
    } catch (err) {
      this.setState({ error: err.message })
    }

  }

  render() {

    const { classes } = this.props

    return (
      <Box>
        <CssBaseline />
        <Box className={'background'} mb={3} >
          <Container maxWidth="lg">
            <Box pt={18}>
              <Typography component="h1" variant="h2" align="left">
                <Box fontWeight="fontWeightBold" m={0}>
                  Address Generator
                </Box>
              </Typography>
            </Box>
            <Typography component="h1" variant="h2" align="left">
              <Box fontWeight="fontWeightBold" m={0}>
                and Tools
              </Box>
            </Typography>
            <GrayTextTypography variant="subtitle1">
              No API needed, no duplicates, Google Autocomplete for maximum efficiency!
            </GrayTextTypography>
            <GrayTextTypography variant="subtitle1">
              Exact coordinates not required. Pay per address, never per request.
            </GrayTextTypography>
            <div>
              <StyledButton
                type="submit"
                onClick={this.handleGoogleLogin}
                variant="contained"
                color="secondary"
                text="Sign in with google"
                className={classes.submit}
              >
                Sign In With Google
              </StyledButton>
            </div>
          </Container>
        </Box>
        <Container maxWidth="lg">
          <Box pt={8} pb={5}>
            <Grid container spacing={1}>
              <Grid xs={12} md={12} lg={4} xl={6} key={'left'} item>
                <Typography variant="h4" align="left">
                  <Box fontWeight="fontWeightBold" m={0}>
                    Select your plan
                  </Box>
                </Typography>
                <Box pt={1} pb={1}>
                  <GrayTextTypography style={{ paddingTop: '10' }} variant="subtitle2">
                    We offer up to 20% discount for partner groups!
                  </GrayTextTypography>
                </Box>
                <Box pb={5}>
                  <GrayTextTypography variant="subtitle2">
                    If you are interested in a partnership please contact us on discord at: ArMaTaS#7757 or on twitter @ArmatasTools
                  </GrayTextTypography>
                </Box>
              </Grid>
              <Grid sm={12} md={10} lg={8} xl={6} key={'right'} item>
                <Subscription handleNoUser={this.handleGoogleLoginStore} />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    )
  }

}

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
})

export default withStyles(styles)(withRouter(Login))