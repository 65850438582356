import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import CardMedia from '@material-ui/core/CardMedia'
import { withStyles } from '@material-ui/styles'

class Faq extends React.Component {

  render() {
    const { classes } = this.props

    return (
      <div>
        <CssBaseline />
        <Container maxWidth="lg" component="main">
          <Box className={classes.paper} mb={5} pt={10} >
            <Typography component="h1" variant="h2" align="left">
              <Box fontWeight="fontWeightBold" m={0}>
                Help
              </Box>
            </Typography>
          </Box>

          <Box pb={3} key={'box-show-0'}>
            <Typography variant="subtitle1" id='question'>
              1. Getting started
            </Typography>
            <CardMedia
              component='iframe'
              style={{height: '500px', width: '800px'}}
              title='test'
              src='https://www.youtube.com/embed/awieKZzuP_M'
            />
          </Box>

          <Box pb={3} key={'box-show-0'}>
            <Typography variant="subtitle1" id='question'>
              2. Address generator
            </Typography>
            <CardMedia
              component='iframe'
              style={{height: '500px', width: '800px'}}
              title='test'
              src='https://www.youtube.com/embed/o1Xix53iTmA'
            />
          </Box>

          <Box pb={2} key={'box-show-0'}>
            <Typography variant="subtitle1" id='question'>
              3. Additional tools
            </Typography>
            <CardMedia
              component='iframe'
              style={{height: '500px', width: '800px'}}
              title='test'
              src='https://www.youtube.com/embed/VQtiGx5utYU'
            />
          </Box>
        </Container>
      </div>
    )
  }
}

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column'
  }
})

export default (withStyles(styles)(Faq))