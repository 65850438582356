import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import Button from '@material-ui/core/Button'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import csv from 'csv-parser'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    maxWidth: 500,
    backgroundColor: '#1D1E23',
  },
}))

export default function MergeToolList(props) {
  const classes = useStyles()
  const [items, setItems] = React.useState(props.options)

  const inputRef = React.createRef()

  const realItems = () => {
    var itemState = items
    if (itemState.length === 0) {
      itemState = props.options
    }
    return itemState
  }

  const deleteTapped = event => {
    var itemState = realItems()
    itemState = [...itemState]
    itemState.splice(event, 1)

    setItems(itemState)
  }

  //Dialog shit
  const [dialogColumn, setDialogColumn] = React.useState(null)
  const [changedName, setChangedName] = React.useState('')

  const editTapped = event => {
    setDialogColumn(event)
    setChangedName('')
  }

  const selectedColumnTitle = () => {
    let dCol = dialogColumn
    if (dCol == null) {
      return ' '
    }
    var itemState = realItems()
    const x = itemState[dCol]

    if (x !== undefined) {
      return x[0]
    } else {
      return ' '
    }
  }

  const editDialogChange = (e) => {
    const v = e.target.value
    setChangedName(v)
  }
  const handleSave = () => {
    const tValue = changedName
    if (tValue.length > 0) {
      const rItems = realItems()
      rItems[dialogColumn][0] = tValue
      setItems(rItems)
    }
    setDialogColumn(null)
  }

  // eslint-disable-next-line no-unused-vars
  const handleClose = event => {
    setDialogColumn(null)
  }



  const SortableItem = SortableElement(({ value, source, nIndex }) =>
    <ListItem alignItems="flex-start" key={value} role={undefined} dense >
      <ListItemText id={`checkbox-list-label-${value}`} primary={
        <React.Fragment>
          <Typography
            component="span"
            variant="body2"
            className={classes.inline}
            color="textPrimary"
          >
            {value}
          </Typography>
          <Typography
            component="span"
            variant="body2"
            className={classes.inline}
            color="textSecondary"
          >
            -  {source}
          </Typography>
        </React.Fragment>
      } />
      <ListItemSecondaryAction>
        <IconButton onClick={() => deleteTapped(nIndex)} edge="end" aria-label="comments">
          <DeleteIcon />
        </IconButton>
        <IconButton onClick={() => editTapped(nIndex)} edge="end" aria-label="comments">
          <EditIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>)

  const onSortEnd = ({ oldIndex, newIndex }) => {
    var toUse = items
    if (toUse.length === 0) {
      toUse = props.options
    }

    const moved = arrayMove(toUse, oldIndex, newIndex)
    setItems(moved)
  }

  const handleDownload = (() => {
    var itemState = items
    if (itemState.length === 0) {
      itemState = props.options
    }

    var toSet = ''
    var highestCount = 0

    itemState.forEach(each => {
      if (0 in each) {
        toSet += each[0] + ', '
      } else {
        toSet += ', '
      }

      const length = Object.keys(each).length - 1
      if (length > highestCount) {
        highestCount = length
      }
    })
    toSet = toSet.substring(0, toSet.length - 2) + '\n'

    for (var i = 1; i < highestCount; i++) {
      const safeI = i
      toSet += itemState.map(each => {
        if (safeI in each) {
          return each[safeI]
        } else {
          return ''
        }
      }).join(',')
      toSet += '\n'
    }

    const element = document.createElement('a')
    const file = new Blob([toSet], { type: 'text/plain' })
    element.href = URL.createObjectURL(file)
    element.download = props.reportName + '- Merged.csv'
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
  })

  const handleUpload = (async () => {
    var itemState = items
    if (itemState.length === 0) {
      itemState = props.options
    }

    var newFileArray = inputRef.current.files

    // eslint-disable-next-line no-undef
    var Readable = require('stream').Readable

    function readableToString(readable) {
      return new Promise((resolve, reject) => {
        const results = []
        readable.on('data', function (chunk) {
          results.push(chunk)
        })
        readable.on('end', function () {
          resolve(results)
        })
        readable.on('error', function (err) {
          reject(err)
        })
      })
    }

    var toAddArray = []

    function pFileReader(file) {
      return new Promise((resolve, reject) => {
        var fr = new FileReader()
        fr.onload = resolve  // CHANGE to whatever function you want which would eventually call resolve
        fr.readAsText(file)
        fr.onerror = reject
      })
    }

    for (var each = 0; each < newFileArray.length; each++) {
      const theFile = newFileArray[each]
      const text = (await pFileReader(theFile)).target.result

      var s = new Readable()
      s.push(text)    // the string you want
      s.push(null)      // indicates end-of-file basically - the end of the stream
      const results = await readableToString(s.pipe(csv()))

      var converted = {}
      for (var i = 0; i < results.length; i++) {
        const current = results[i]
        const keys = Object.keys(current)
        for (var j = 0; j < keys.length; j++) {
          const curKey = keys[j]
          if (i === 0) {
            converted[curKey] = [curKey]
          }
          converted[curKey].push(current[curKey])
        }
      }
      converted = Object.values(converted)

      const makeID = (length) => {
        var result = ''
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
        var charactersLength = characters.length
        for (var i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength))
        }
        return result
      }


      const maybeFinal = converted.map(value => {
        const toRet = Object.assign({}, value)
        toRet.source = theFile.name
        toRet.sourceHash = makeID(10)
        return toRet
      })
      toAddArray = toAddArray.concat(maybeFinal)
    }

    itemState = itemState.concat(toAddArray)
    setItems(itemState)

    var resizeEvent = new Event('resize')

    window.dispatchEvent(resizeEvent)

  })

  const SortableList = SortableContainer(({ items, backup }) => {
    var toUse = items
    if (items.length === 0) {
      toUse = backup
    }
    return (
      <div style={{ paddingBottom: '10px', paddingTop: '10px', backgroundColor: '#1D1E23' }}>
        <Typography style={{ fontSize: '90%' }} align="center" variant="h6" color="textPrimary">
          {props.reportName}
        </Typography>
        <List style={{ maxHeight: '80%', overflow: 'auto' }} className={classes.root}>
          {toUse.map((value, index) => {
            const realValue = value[0]
            const source = value.source
            const sourceHash = value.sourceHash
            return <SortableItem nIndex={index} key={`item-${realValue}-${sourceHash}`} index={index} value={realValue} source={source} />
          })}
        </List>

        <div style={{
          display: 'flex',
          paddingTop: '10px',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
          <Button
            style={{ textAlign: 'center' }}
            type="submit"
            variant="contained"
            color="secondary"
            onClick={handleDownload}
          >
            Download merged
          </Button>
          <br style={{ height: '2px' }}></br>
          <input accept=".csv"
            ref={inputRef}
            style={{ display: 'none' }}
            id="contained-button-file"
            multiple
            onChange={handleUpload}
            type="file"
          />
          <label htmlFor="contained-button-file">
            <Button color="secondary" component="span">
              Import CSV
            </Button>
          </label>
        </div>

      </div>
    )
  })
  return <div>
    <SortableList helperClass="mergeSortableHelper" distance={10} helperContainer={props.helperContainer} items={items} backup={props.options} onSortEnd={onSortEnd} />
    <Dialog open={dialogColumn != null} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Edit column </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Type the desired name for {selectedColumnTitle()} below:
        </DialogContentText>
        <TextField
          autoFocus
          color="secondary"
          margin="dense"
          id="name"
          onChange={editDialogChange}
          label={selectedColumnTitle()}
          type="email"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="secondary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  </div>
}