import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import GoogleMapsPlace from '../../../components/common/GoogleMapsPlace'
import Box from '@material-ui/core/Box'
import Slider from '@material-ui/core/Slider'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import Collapse from '@material-ui/core/Collapse'
import Fade from '@material-ui/core/Fade'
import Button from '@material-ui/core/Button'
import GrayTextTypography from '../../../components/common/GrayTextTypography'
import { useHistory } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Context } from '../../../constants'
import firebase from '../../../firebase/firebase'
import { connect } from 'react-redux'
import { setAlert } from '../../../redux/actions/alert'
import Typography from '@material-ui/core/Typography'
import { socialMedia } from '../../../constants'

const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: '#BF9B30',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})

const GenerationForm = ({ setAlert, ...props }) => {
  const classes = useStyles()

  const [advancedFormatting, setAdvancedFormatting] = useState(false)
  const [rejectDuplicates, setRejectDuplicates] = useState(false)
  const [validInput, setValidInput] = useState(false)
  const [resetGmaps, setResetGmaps] = useState(false)

  const userContext = useContext(Context.UserContext)
  const [agreedTC] = useState(userContext.agreedTC)

  const [address, setAddress] = useState('')
  const [addressCount, setAddressCount] = useState(10)
  const [addressRadius, setAddressRadius] = useState(2.0)
  const [fixedPostalCode, setFixedPostalCode] = useState(false)
  const [fixedAddress, setFixedAddress] = useState(true)
  const [notEnoughCredits, setNotEnoughCredits] = useState(false)

  const [awaitingReport, setAwaitingReport] = useState(false)

  useEffect(() => {
    setAwaitingReport(false)
  }, [props.awaitingReport])

  const history = useHistory()

  useEffect(() => {
    if (!agreedTC && firebase.auth().currentUser != null) {

      setAlert('notc', {
        severity: 'warning',
        timer: 0,
        message: 'In order to use the app you have to accept the Terms and Conditions',
        button: 'Go to terms and conditions',
        buttonAction: (() => {
          history.push('/terms')
        })
      })
    }
  }, [agreedTC, history, setAlert])

  useEffect(() => {
    if (!props.fixedLocations && addressRadius < 1.5) {
      setAddressRadius(1.5)
    }
  }, [props.fixedLocations, addressRadius])

  React.useEffect(() => {
    var checked = 0
    if (address.length > 0) {
      checked = checked + 1
    }
    if (addressCount > 0 && addressCount < 1001) {
      checked = checked + 1
    }
    if (addressRadius > 0 && addressRadius < 10) {
      checked = checked + 1
    }
    setValidInput(checked === 3)

    const remaining = userContext.credits
    const cost = addressCount * (props.fixedLocations ? 3 : 2)
    if (cost > remaining) {
      setNotEnoughCredits(true)
    } else {
      setNotEnoughCredits(false)
    }

  }, [address, addressCount, addressRadius, userContext.credits, props.fixedLocations, userContext.strikes])

  const handleChange = event => {
    if (event.target.name === 'location') {
      setAddress(event.target.value)
    }
    if (event.target.name === 'addresscount') {
      setAddressCount(event.target.value)
    }
    if (event.target.id === 'radius') {
      //setAddressRadius(event.target.value) This is done below in another method
    }
    if (event.target.name === 'checkedB') {
      const fixedPostalCode = event.target.checked
      if (!fixedPostalCode && !fixedAddress) {
        setFixedAddress(true)
      }
      setFixedPostalCode(event.target.checked)
    }
    if (event.target.name === 'checkedC') {
      const useFixedAddress = event.target.checked
      if (!useFixedAddress && !fixedPostalCode) {
        setFixedPostalCode(true)
      }
      setFixedAddress(event.target.checked)
    }
    if (event.target.name === 'checkedD') {
      setRejectDuplicates(event.target.checked)
    }
    if (event.target.name === 'checkedFormatting') {
      setAdvancedFormatting(event.target.checked)
      props.advancedUpdated(event.target.checked)
    }
  }

  const sliderChange = (event, value) => {
    setAddressRadius(value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    if (!agreedTC) {
      history.push('/terms')
      return
    }
    if (address.length === 0 || addressCount === 0 || addressRadius === 0 || addressRadius > 6) {
      return
    }

    const remaining = userContext.credits
    const cost = addressCount * (props.fixedLocations ? 3 : 2)
    if (cost > remaining) {
      //FIXME: provide proper error message here
      return
      // const eClass = {
      //     severity: "error",
      //     errorTitle: "Error",
      //     errorMessage: "You do not have enough credits!"
      // }
      // setState({ showAlert: eClass })
    } else {
      setResetGmaps(!resetGmaps)
      setAwaitingReport(true)

      const event = { target: { value: '', name: 'location' } }
      handleChange(event)

      //replace var with let of const

      var addMessage = firebase.functions().httpsCallable('createReportNew')
      const idFilters = props.filterList.map(each => { return each.id })
      
      addMessage({
        newPricing: true, 
        filters: idFilters, 
        rejectDuplicates: rejectDuplicates, 
        addressName: address, 
        count: addressCount, 
        radius: addressRadius, 
        useFixedLocation: props.fixedLocations, 
        useFixedPostal: fixedPostalCode, 
        useFixedAddress: fixedAddress 
      }).then(() => {

        setAlert('genOk', {
          severity: 'success',
          timer: 5000,
          message: 'Report generated successfully!'
        })
      }).catch(error => {

        console.log(error)

        setAlert('genFail', {
          severity: 'error',
          timer: 5000,
          message: error.message
        })
      })
    }
    event.preventDefault()
  }

  return (
    <form className={classes.form} noValidate>
      <GoogleMapsPlace
        key={resetGmaps}
        label="Target Address"
        aa={handleChange}
        variant="outlined"
        margin="normal"
        required
        color="secondary"
        fullWidth
        id="location"
        name="location"
      />

      <Box pt={2} pb={1}>
        <GrayTextTypography id="discrete-slider" gutterBottom>
          Radius (km): {addressRadius}
        </GrayTextTypography>
        <Slider
          defaultValue={2}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="auto"
          color="primary"
          step={0.1}
          min={props.fixedLocations ? 0.5 : 1.5}
          value={addressRadius}
          onChange={sliderChange}
          id="radius"
          name="radius"
          max={props.fixedLocations && fixedPostalCode ? 2 : 6}
        />
      </Box>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="addresscount"
        label="Address Count"
        name="addresscount"
        type="number"
        color="secondary"
        onChange={handleChange}
        value={addressCount}
      />

      <GrayTextTypography>
        <Checkbox
          checked={advancedFormatting}
          onChange={handleChange}
          name="checkedFormatting"
        />
                Advanced report formatting
      </GrayTextTypography>

      <GrayTextTypography>
        <Checkbox
          checked={rejectDuplicates}
          onChange={handleChange}
          name="checkedD"
        />
                Reject duplicates across reports
      </GrayTextTypography>

      <Collapse in={props.fixedLocations}>
        <Fade in={props.fixedLocations}>
          <GrayTextTypography>
            <Checkbox
              checked={fixedPostalCode}
              onChange={handleChange}
              name="checkedB"
            />
                        Search in same postal code
          </GrayTextTypography>
        </Fade>
        <Fade in={props.fixedLocations}>
          <GrayTextTypography>
            <Checkbox
              checked={fixedAddress}
              onChange={handleChange}
              name="checkedC"
            />
                        Search with fixed address
          </GrayTextTypography>
        </Fade>
      </Collapse>

      <Box pt={2} pb={1}>
        {userContext.strikes >= 1 && userContext.strikes < 3 ? <div className='strikesWarning'>
          <Typography>
            Warning, you have {userContext.strikes} strikes.
          </Typography>
          <Typography>
            If you get 3 you will not be able to generate temporarily.
          </Typography>
          <Typography>
            Please try to change your entry parameters, for support contact us on <a href={socialMedia.discord} id='social'>Discord</a>
          </Typography>
        </div> : userContext.strikes === 3 ? <div className='strikesWarning'>
          <Typography>
            You have failed 3 times already to generate addresses.
          </Typography>
          <Typography>
            Please wait until tomorrow, for support contact us on <a href={socialMedia.discord} id='social'>Discord</a>
          </Typography>
        </div> : ''}
      </Box>
      <Tooltip title={
        agreedTC ?
          (notEnoughCredits ? 'You do not have enough credits, please top up!' : '')
          : 'You must agree to the Terms and Conditions to use this feature!'} aria-label="add">
        <div style={{ width: '150px' }} className={classes.wrapper}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            fullWidth
            disabled={!validInput || !agreedTC || notEnoughCredits || awaitingReport}
            onClick={handleSubmit}
            className={classes.submit}
          >
            Generate
          </Button>
          {awaitingReport && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </Tooltip>
    </form>
  )
}

const mapActionsToProps = {
  setAlert
}

export default connect(null, mapActionsToProps)(GenerationForm)
