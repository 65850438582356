import React, { useState } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import { withStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import GrayTextTypography from '../../components/common/GrayTextTypography'
import Pricing from '../../components/common/Pricing'
import StyledButton from '../../components/StyledButton'
import firebase2 from '../../firebase/firebase'
import CircularProgress from '@material-ui/core/CircularProgress'
import { setAlert } from '../../redux/actions/alert'
import { connect } from 'react-redux'
import Subscription from '../../components/common/Subscription'
import raffle from '../../assets/raffle-rotated.png'

const useStyles = makeStyles(() => ({
  paper: {
    background: '#0D0E11'
  },
  grayText: {
    top: '20px'
  },
  footerLink: {
    fontSize: '90%'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -5,
  },
  loadingWrapper: {
    position: 'relative',
  },
}))

const Store = ({ setAlert }) => {

  const classes = useStyles()

  const [promoCode, setPromoCode] = useState('')
  const [loadingPromo, setLoadingPromo] = useState('')

  const handlePromoChange = (e) => {

    const code = e.target.value

    setPromoCode(code)
  }

  const addPromo = () => {

    firebase2.analytics().logEvent('add_promo', { name: promoCode })

    setLoadingPromo(true)

    firebase2.functions().httpsCallable('promoCode')({ promoCode }).then((resp) => {

      setLoadingPromo(false)
      setPromoCode('')

      const type = resp.data['couponType']
      const litS = type === 'lite_subscription'

      setAlert('promoOk', {
        severity: 'success',
        timer: 5000,
        message: litS ? 'You have entered a promo code. 300 credits and 1 free month subscription has been added to your account' : 'Stripe coupon applied, enjoy your discounted subscription!'
      })
    }).catch(error => {

      console.log(error)

      setLoadingPromo(false)

      setAlert('promoFail', {
        severity: 'error',
        timer: 5000,
        message: error.message
      })
    })
  }

  return (
    <Container maxWidth="lg">
      <Box pt={15}>
        <CssBaseline />
        <Grid container spacing={1}>
          <Grid md={12} lg={6} key={'left'} item>
            <Typography variant="h4" align="left">
              <Box fontWeight="fontWeightBold" m={0}>
                Plans and Add-ons
              </Box>
            </Typography>
            <Box pt={1} pb={5}>
              <GrayTextTypography style={{ paddingTop: '10' }} variant="subtitle2">
                Renew/Cancel your subscription or add more credits to your plan
              </GrayTextTypography>
              <GrayTextTypography style={{ paddingTop: '10' }} variant="subtitle2">
                A general address costs
                <div style={{ display: 'inline' }}>
                  <Typography display="inline" variant="subtitle2" color="secondary">
                    {' '}2 <img className="raffle" src={raffle} alt=""/>
                  </Typography>
                </div>
                , while an exact address requires
                <div style={{ display: 'inline' }}>
                  <Typography display="inline" variant="subtitle2" color="secondary">
                    {' '}3 <img className="raffle" src={raffle} alt=""/>
                  </Typography>
                </div>
                .
              </GrayTextTypography>
            </Box>
            <Box pb={5} style={{ display: 'flex', alignItems: 'center', width: '300px' }}>
              <TextField
                id="outlined-basic"
                style={{ flexGrow: 2 }}
                label="Promo Code"
                color="secondary"
                value={promoCode}
                onChange={handlePromoChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    addPromo()
                  }
                }}
                variant="outlined"
              />
              <Box className={classes.loadingWrapper} pl={2}>
                <StyledButton
                  className={classes.submit}
                  style={{ height: '100%' }}
                  type="submit"
                  text="Add"
                  color="secondary"
                  onClick={addPromo}
                  disabled={loadingPromo || promoCode.length === 0}
                  variant="contained"
                >
                </StyledButton>
                {loadingPromo && <CircularProgress color="secondary" size={24} className={classes.buttonProgress} />}
              </Box>
            </Box>
          </Grid>
          <Grid container spacing={5} md={12} lg={6} key={'right'} item>
            <Subscription />
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ paddingTop: '40px' }}>
          <Pricing />
        </Grid>
      </Box >
    </Container>
  )
}

Store.propTypes = {
  classes: PropTypes.object.isRequired,
}

const styles = () => ({
})

const mapActionsToProps = {
  setAlert
}

export default connect(null, mapActionsToProps)(withStyles(styles)(Store))
