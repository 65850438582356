export default {
  apiKey: 'AIzaSyAxyqdRJDZtIgFDXYdWF_ODQNTgbxoLtFY',
  authDomain: 'expresser-56dd3.firebaseapp.com',
  databaseURL: 'https://expresser-56dd3.firebaseio.com',
  projectId: 'expresser-56dd3',
  storageBucket: 'expresser-56dd3.appspot.com',
  messagingSenderId: '504664793904',
  appId: '1:504664793904:web:85dd4d6f6358ea1a37def5',
  measurementId: 'G-CWPTGF895X'

}