import React, { useContext, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Box from '@material-ui/core/Box'
import ReportItem from './ReportItem'
import { Context } from '../../../constants'
import TablePaginationActions from './TablePaginationActions'
import TablePagination from '@material-ui/core/TablePagination'
import TableFooter from '@material-ui/core/TableFooter'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    borderSpacing: '150px'
  },
  pagination: {
    backgroundColor: '#111217'
  }
})

export default function ReportTable(props) {
  const classes = useStyles()

  const user = useContext(Context.UserContext)

  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)

  const tableBodyMemo = useMemo(() => <TableBody>
    {props.reports.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage).map((row, key) => ([
      <ReportItem key={row.id} subscribed={user.subscribed} mKey={key} report={row} />,
      <TableRow key={row.id + ' bottom'} pt={3}>
        <TableCell style={{ backgroundColor: '#111217' }}>
          <Box pt={1}>
          </Box>
        </TableCell>
      </TableRow>
    ]
    ))}
  </TableBody>, [props.reports, user, currentPage, rowsPerPage])

  // eslint-disable-next-line no-unused-vars
  const onChangePage = (event, newPage) => {
    setCurrentPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setCurrentPage(0)
  }

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>LOCATION</TableCell>
            <TableCell>DATE</TableCell>
            <TableCell>ADDRESS COUNT</TableCell>
            <TableCell>RADIUS</TableCell>
            <TableCell>DOWNLOAD</TableCell>
            {user.subscribed && <TableCell>BUILD CSV</TableCell>}
            <TableCell> </TableCell>
          </TableRow>
        </TableHead>
        {tableBodyMemo}
        <TableFooter>
          <TableRow>
            <TablePagination
              className={classes.pagination}
              rowsPerPageOptions={[5, 10, 25]}
              count={props.reports.length}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true
              }}
              onChangePage={onChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}
