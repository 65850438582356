import React from 'react'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import ReportTable from './components/ReportTable'
import { withStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import FileOrderList from './components/file_order/FileOrderList'
import Box from '@material-ui/core/Box'
import GrayTextTypography from '../../components/common/GrayTextTypography'
import Fade from '@material-ui/core/Fade'
import ReactSwitch from 'react-switch'
import Collapse from '@material-ui/core/Collapse'
import GenerationForm from './components/GenerationForm'
import CssBaseline from '@material-ui/core/CssBaseline'
import firebase from 'firebase/app'
import Filter from 'shared4/filter'
import raffle from '../../assets/raffle-rotated.png'


class Homepage extends React.Component {

  constructor(props) {
    super(props)

    this.state = { gotNewReport: false, advancedFormatting: false, rejectDuplicates: false, fixedReports: [], generalReports: [], fixedLocations: false, selectedFilterList: Filter.all().filter(each => each.checkedByDefault) }

    this.postalTickEnabled = this.postalTickEnabled.bind(this)
    this.handleFilterMoved = this.handleFilterMoved.bind(this)
    this.handleSwitchChange = this.handleSwitchChange.bind(this)
    this.advancedUpdated = this.advancedUpdated.bind(this)
    this.activeFilterList = this.activeFilterList.bind(this)
  }

  activeFilterList() {
    if (this.state.advancedFormatting) {
      return this.state.selectedFilterList
    }
    return Filter.all().filter(each => each.checkedByDefault)
  }

  postalTickEnabled() {
    return this.state.fixedLocations
  }

  componentDidMount() {
    const user = firebase.auth().currentUser

    if (user != null) {
      const userID = user.uid

      const baseQuery = firebase.firestore().collection('/reports').where('userID', '==', userID)

      baseQuery.where('fixedLocation','==',false).orderBy('dateCreated', 'desc').onSnapshot(docs => {
        this.setState({generalReports: docs.docs, gotNewReport: !this.state.gotNewReport})
      }).bind(this)
      baseQuery.where('fixedLocation','==',true).orderBy('dateCreated', 'desc').onSnapshot(docs => {
        this.setState({fixedReports: docs.docs, gotNewReport: !this.state.gotNewReport})
      }).bind(this)
    }
  }

  handleSwitchChange() {
    this.setState({ fixedLocations: !this.state.fixedLocations })
  }

  handleFilterMoved(newList) {
    this.setState({selectedFilterList: newList})
  }

  advancedUpdated(newValue) {
    this.setState({ advancedFormatting: newValue })
  }

  render() {
    const { fixedLocations, advancedFormatting, fixedReports, generalReports } = this.state

    const text = [
      {
        first: 'Perfect to redirect packages for Footpatrol, JD Sports raffles.',
        second: 'The input has to contain an exact address: street number, street, city, country if you want to redirect to your address/postcode.'
      },
      {
        first: 'Perfect for raffles such as Naked, Caliroots, Holywood, Soto, Patta etc.',
        second: 'The input has to contain the city and country (street number, street, county, postcode are optional).'
      }
    ]

    return (
      <Box pt={15}>
        <Container maxWidth="lg">
          <CssBaseline />
          <Grid container spacing={1}>
            <Grid xs={12} md={12} lg={6} key={'left'} item>
              <Typography variant="h4" align="center">
                <div style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                  {['Specific Address Generator', 'General Address Generator'].map((value, index) => {
                    return <Fade key={index} style={{ gridRowStart: 1, gridColumnStart: 1 }} in={(index === 0) === fixedLocations}>
                      <Box color="white" textAlign="left" fontWeight="fontWeightBold" m={0}>
                        {value}
                      </Box>
                    </Fade>
                  })}
                </div>
              </Typography>
              <Box pt={2} pb={1} style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                {text.map((value, index) => {
                  return <Fade key={index} style={{ gridRowStart: 1, gridColumnStart: 1 }} in={(index === 0) === fixedLocations}>
                    <Box pt={2}>
                      <GrayTextTypography variant="subtitle2">
                        {value.first}
                      </GrayTextTypography>
                      <GrayTextTypography variant="subtitle2">
                        {value.second}
                      </GrayTextTypography>
                    </Box>
                  </Fade>
                })}
              </Box>
            </Grid>
            <Grid xs={12} md={12} lg={6} key={'right'} item>
              <Box style={{ alignItems: 'center', display: 'flex' }}>
                <ReactSwitch
                  checkedIcon={false}
                  uncheckedIcon={false}
                  offColor="#212428"
                  offHandleColor="#BF9B30"
                  onColor="#212428"
                  height={35}
                  width={70}
                  onHandleColor="#BF9B30"
                  checked={fixedLocations}
                  onChange={this.handleSwitchChange}
                  name="checkedA"
                />
                <Box pl={2}>
                  <Typography component="h1" variant="h6">
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                      <Fade style={{ gridRowStart: 1, gridColumnStart: 1 }} in={fixedLocations}>
                        <Box style={{ color: 'white' }}>
                                                    Exact address
                        </Box>
                      </Fade>
                      <Fade style={{ gridRowStart: 1, gridColumnStart: 1 }} in={!fixedLocations}>
                        <Box style={{ color: 'white' }}>
                                                    General address
                        </Box>
                      </Fade>
                    </div>
                  </Typography>
                  <GrayTextTypography style={{ fontSize: '85%' }} component="h1" variant="h6">
                    {fixedLocations ? 
                      <span>1 address = 3 <img className="raffle" src={raffle} alt=""/></span> :
                      <span>1 address = 2 <img className="raffle" src={raffle} alt=""/></span>}
                  </GrayTextTypography>
                </Box>
              </Box>
              <GenerationForm
                advancedUpdated={this.advancedUpdated}
                filterList={this.activeFilterList()}
                awaitingReport={this.state.gotNewReport}
                fixedLocations={fixedLocations}
              />
            </Grid>
          </Grid>
          <Collapse in={advancedFormatting}>
            <Box pt={4}>
              <GrayTextTypography>
                                Customize CSV Headers
              </GrayTextTypography>
              <GrayTextTypography>
                                You can reorganize them by drag and dropping in the desired order
              </GrayTextTypography>
            </Box>
            <Box pb={1}>
              <FileOrderList onMoved={this.handleFilterMoved} />
            </Box>
          </Collapse>

          <Box style={{ display: 'grid' }} pt={3} pb={2}>
            <Fade in={fixedLocations}>
              <Box style={{ gridArea: '1 / 1' }}>
                <ReportTable reports={fixedReports} />
              </Box>
            </Fade>
            <Fade in={!fixedLocations}>
              <Box style={{ gridArea: '1 / 1' }}>
                <ReportTable reports={generalReports} />
              </Box>
            </Fade>
          </Box>

          <Typography variant="body2" color="textSecondary" align="center">
            {'Full support for the following countries: United Kingdom, USA, France, Germany, Austria, Italy, Spain, Norway, Denmark, Finland, Sweden, Iceland.'}
            <br></br>
            {'If your country is not present above please contact us and we will make it available.'}
          </Typography>
        </Container>
      </Box>
    )
  }
}

Homepage.propTypes = {
  classes: PropTypes.object.isRequired,
}

const styles = () => ({
})

export default withStyles(styles)(Homepage)