import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { generate, countryList } from './Generation'
import StyledButton from '../../../../components/StyledButton'
import Chip from '@material-ui/core/Chip'
import GrayTextTypography from '../../../../components/common/GrayTextTypography'
import Filter from 'shared4/filter'
import CircularProgress from '@material-ui/core/CircularProgress'
import firebase from '../../../../firebase/firebase'
import { setAlert } from '../../../../redux/actions/alert'
import { connect } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '90%',
    marginLeft: '5%',
    backgroundColor: theme.palette.background.paper,
  },
  droppableButton: {
    borderRadius: 30
  },
  wrapper: {
    position: 'relative',
    width: '150px'
  },
  buttonProgress: {
    color: '#BF9B30',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

const allF = Filter.filters()

const data = {
  checked: [allF.fullName, allF.fullPhone, allF.dateOfBirth],
  unchecked: [allF.phonePrefix, allF.shortPhone, allF.firstName, allF.lastName]
}

const LocalGenerationList = ({ setAlert }) => {
  const classes = useStyles()
  const [items, setItems] = React.useState(data.checked.concat(data.unchecked))
  const [checked, setChecked] = React.useState(data.checked)
  const [countryText, setCountryText] = React.useState('')
  const [countText, setCountText] = React.useState('')
  const [buttonEnabled, setButtonEnabled] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (newChecked.length === 1 && currentIndex !== -1) {
      return
    }

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const moved = arrayMove(items, oldIndex, newIndex)
    setItems(moved)
  }


  // Generation stuff start

  const handleGeneration = () => {
    const country = countryText
    const countRef = countText

    const x = generate(country, countRef, checked)

    x.then(each => {
      const element = document.createElement('a')
      const file = new Blob([each], { type: 'text/plain' })
      element.href = URL.createObjectURL(file)
      element.download = 'profiles.csv'
      document.body.appendChild(element)
      setTimeout(function () {
        setLoading(false)
        element.click()
      }, 2000)

      setLoading(true)
      setCountText('')
      setCountryText('')
      handleBoth('', '')

      const userID = firebase.auth().currentUser.uid
      firebase.firestore().collection('/profileGenerations').doc().set({
        user: userID,
        count: countRef
      })

      setAlert('localOk', {
        severity: 'success',
        timer: 5000,
        title: 'Successful generation!'
      })

    }).catch(error => {
      console.log(error)

      setAlert('localFail', {
        severity: 'error',
        timer: 5000,
        title: error + ' Possible values:',
        message: countryList().filter(each => each !== 'Default').reduce((prev, cur) => prev+cur+', ', ' ').slice(0, -2)
      })
    })

  }

  const handleBoth = (countryText, countText) => {
    const intCount = parseInt(countText)
    if (intCount > 0 && intCount <= 500 && countryText.length > 0) {
      setButtonEnabled(true)
    } else {
      setButtonEnabled(false)
    }
  }

  const handleCountryUpdate = (e) => {
    setCountryText(e.target.value)

    handleBoth(e.target.value, countText)
  }

  const handleCountChange = (e) => {
    setCountText(e.target.value)

    handleBoth(countryText, e.target.value)
  }


  // Generation stuff end

  const SortableItem = SortableElement(({ value }) => {
    return <li style={{ listStyleType: 'none', display: 'inline-block', marginLeft: '10px', marginRight: '10px', marginTop: '10px' }}>
      <Chip onClick={handleToggle(value)} color={checked.indexOf(value) !== -1 ? 'secondary' : 'primary'} label={value.displayName} />
    </li>
  })

  const SortableList = SortableContainer(({ items }) => {
    return (
      <ul style={{ paddingBottom: '10px', paddingLeft: '0px' }}>
        <Box pt={4} pb={1}>
          <GrayTextTypography style={{ paddingTop: '10', textAlign: 'left' }} variant="subtitle2">
                        Generated fields
          </GrayTextTypography>
          <GrayTextTypography style={{ paddingTop: '10', textAlign: 'left' }} variant="subtitle2">
                        You can reorganize them by drag and drop in the desired order or tap
                        to disable them
          </GrayTextTypography>
        </Box>

        {items.map((value, index) => (
          <SortableItem key={`item-${value.id}`} index={index} value={value} />
        ))}


      </ul>
    )
  })

  return <div>

    <TextField key={'countryTextField'} value={countryText} onChange={handleCountryUpdate} variant="outlined" color="secondary" style={{ width: '100%' }} InputLabelProps={{ shrink: true }} label="Country" />
    <Box pt={3} />
    <TextField key={'countTextField'} value={countText} onChange={handleCountChange} variant="outlined" color="secondary" id="standard-basic222" type="number" InputLabelProps={{ shrink: true }} style={{ marginTop: '10px', width: '100%' }} label="Number of entries" />
    <SortableList distance={5} axis="xy" items={items} onSortEnd={onSortEnd} />

    <Box pt={4} pl={1}>
      <div className={classes.wrapper}>
        <StyledButton
          disabled={!buttonEnabled}
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          text="Generate"
          onClick={handleGeneration}
          className={classes.submit}
        />
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </Box>
  </div>
}

const mapActionsToProps = {
  setAlert
}

export default connect(null, mapActionsToProps)(LocalGenerationList)