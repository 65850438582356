import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles({
  root: {
  },
  label: {
    textTransform: 'capitalize',
    margin: '10 em'
  },
})

export default function StyledButton(props) {
  const classes = useStyles()

  return (
    <Button
      classes={{
        root: classes.root, // class name, e.g. `classes-nesting-root-x`
        label: classes.label, // class name, e.g. `classes-nesting-label-x`
      }}
      {...props}
    >
      {props.text}
    </Button>
  )
}