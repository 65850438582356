import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { withStyles } from '@material-ui/styles'

class Faq extends React.Component {

  render() {
    const { classes } = this.props

    return (
      <div>
        <CssBaseline />
        <Container maxWidth="lg" component="main">
          <Box className={classes.paper} mb={5} pt={10} >
            <Typography component="h1" variant="h2" align="left">
              <Box fontWeight="fontWeightBold" m={0}>
                FAQ
              </Box>
            </Typography>
          </Box>

          <Box pb={2} key={'box-show-0'}>
            <Typography variant="subtitle1" id='question'>
              How much experience should I have before using ArMaTaS Tools?
            </Typography> 
            <Typography variant="subtitle1" id='response'>
              Ideally you should have a raffle bot. However, our expert team will explain everything
              you need to know. You can join our <a href='https://discord.com/invite/MSuAMfy' id='social'>Discord channel</a> at any time.
            </Typography> 
          </Box>

          <Box pb={2} key={'box-show-0'}>
            <Typography variant="subtitle1" id='question'>
              How can I get notifications about new products and releases ?
            </Typography> 
            <Typography variant="subtitle1" id='response'>
              You can join our <a href='https://twitter.com/armatastools' id='social'>twitter community</a> or join our <a href='https://discord.com/invite/MSuAMfy' id='social'>discord server</a>
            </Typography> 
          </Box>

          <Box pb={2} key={'box-show-0'}>
            <Typography variant="subtitle1" id='question'>
              How much time does it take to generate 100 addresses?
            </Typography> 
            <Typography variant="subtitle1" id='response'>
              Under 45 seconds.
            </Typography> 
          </Box>

          <Box pb={2} key={'box-show-0'}>
            <Typography variant="subtitle1" id='question'>
              Can I top-up if I ran out of credits?
            </Typography> 
            <Typography variant="subtitle1" id='response'>
              Yes. We have amazing discounts for our subscribers.
            </Typography> 
          </Box>

          <Box pb={2} key={'box-show-0'}>
            <Typography variant="subtitle1" id='question'>
              Are you partnering with discord groups?
            </Typography> 
            <Typography variant="subtitle1" id='response'>
              We are always looking for partners. We currently offer 20% discounts for our partner
              groups for subscription-based plans.
            </Typography> 
          </Box>

          <Box pb={2} key={'box-show-0'}>
            <Typography variant="subtitle1" id='question'>
              Are the addresses real?
            </Typography> 
            <Typography variant="subtitle1" id='response'>
              All addresses are real, generated using the best algorithm on the market.
            </Typography> 
          </Box>

          <Box pb={2} key={'box-show-0'}>
            <Typography variant="subtitle1" id='question'>
              What countries do you support?
            </Typography> 
            <Typography variant="subtitle1" id='response'>
              United Kingdom, France, Spain, Germany, Italy, Netherlands, Austria, Switzerland, Czech Republic, Denmark, Sweeden, Finland, Norway, Iceland, Canada, USA, Australia, Romania and Russia.
              We are constantly updating the list, and we can add new countries if you request it. To request a new country please contact us. 
            </Typography> 
          </Box>


          <Box pb={2} key={'box-show-0'}>
            <Typography variant="subtitle1" id='question'>
              How specific are your addresses?
            </Typography> 
            <Typography variant="subtitle1" id='response'>
              We generate either:
              <ol>
                <li>
                  City-specific addresses. Random addresses within a city. Example: London, UK.
                </li>
                <li>
                  Exact location addresses. Specific addresses around a user-specified location.
                  Example: 71 Central St, London, EC1V 8AB, UK.
                </li>
              </ol>
            </Typography> 
          </Box>

          <Box pb={2} key={'box-show-0'}>
            <Typography variant="subtitle1" id='question'>
              Can we restrict the radius for searching addresses?
            </Typography> 
            <Typography variant="subtitle1" id='response'>
              You can restrict the radius up to 0.5km for very specific generations. Furthermore, we
              provide 3 more options for our expert users.
              <ol>
                <li>
                  You can search within one postcode.
                </li>
                <li>
                  You can search around your address, with no regard to the postcode.
                </li>
                <li>
                  You can search both around your address and within one postcode.
                </li>
              </ol>
            </Typography> 
          </Box>

          <Box pb={2} key={'box-show-0'}>
            <Typography variant="subtitle1" id='question'>
              What other features do you provide?
            </Typography> 
            <Typography variant="subtitle1" id='response'>
              Along with state-of-the-art address generators we also provide to our subscribers:
              <ol>
                <li>
                  ID Generators: Country specific names and phones + DoB generator.
                </li>
                <li>
                  CSV Tool: Each raffle bot has its own CSV templates, which means users need to
                  keep track of 10-20 CSV templates. With our tool you can generate custom
                  headers, edit data, change order of headers etc. All on our website.
                </li>
              </ol>
            </Typography> 
          </Box>
        </Container>
      </div>
    )
  }
}

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column'
  }
})

export default (withStyles(styles)(Faq))