import React from 'react'

export const Context = {
  UserContext: React.createContext('UserContext')
}

const devStripe = {
  stripeAPIKey: 'pk_test_51GtkADG1SnPXHbeoGkW3JtLIljjVKJWlgWM0jY8TZzpWrfGzKgF5PuacCs9Bvf82euFm7h0FqxsZLzaqZqF7q9mV00doiB9lkv',
  specialTier: {
    stripeID: 'price_1HJLuDG1SnPXHbeoc8umzoyH'
  },
  tiers: [
    {
      price: '12',
      credits: 300,
      bonus: 0,
      buttonText: 'Top up',
      buttonVariant: 'outlined',
      stripeID: 'price_1HHBSDG1SnPXHbeouCnmiJ9X'
    },
    {
      monthly: false,
      price: '24',
      credits: 600,
      bonus: 50,
      buttonText: 'Top up',
      buttonVariant: 'outlined',
      stripeID: 'price_1HHBSuG1SnPXHbeo0mHDXmDQ'
    },
    {
      price: '30',
      credits: 750,
      bonus: 100,
      buttonText: 'Top up',
      buttonVariant: 'outlined',
      stripeID: 'price_1HHBTEG1SnPXHbeo49HaWODA'
    },
    {
      price: '40',
      credits: 1000,
      bonus: 200,
      buttonText: 'Top up',
      buttonVariant: 'outlined',
      stripeID: 'price_1HHBTYG1SnPXHbeo0X6FmIlG'
    },
    {
      price: '50',
      credits: 1250,
      bonus: 300,
      description: ['Address pricing:', '1 generic = 2 credits', '1 specific = 3 credits'],
      buttonText: 'Top up',
      buttonVariant: 'outlined',
      stripeID: 'price_1HHBUIG1SnPXHbeovzBttGQy'
    },
    {
      price: '65',
      credits: 1625,
      bonus: 400,
      description: ['Address pricing:', '1 generic = 2 credits', '1 specific = 3 credits'],
      buttonText: 'Top up',
      buttonVariant: 'outlined',
      stripeID: 'price_1HHBUjG1SnPXHbeoZTji4E8y'
    }
  ]
}

const prodStripe = {
  stripeAPIKey: 'pk_live_fzE92NLRDeEgsCrs10NNt1WH00znEEF2di',
  specialTier: {
    stripeID: 'price_1HMvVWG1SnPXHbeo9ng2kRQQ'
  },
  tiers: [
    {
      price: '12',
      credits: 300,
      bonus: 0,
      buttonText: 'Top up',
      buttonVariant: 'outlined',
      stripeID: 'price_1HMvWoG1SnPXHbeo9xwRtXyp'
    },
    {
      monthly: false,
      price: '24',
      credits: 600,
      bonus: 50,
      buttonText: 'Top up',
      buttonVariant: 'outlined',
      stripeID: 'price_1HMvXkG1SnPXHbeodPu8v42L'
    },
    {
      price: '30',
      credits: 750,
      bonus: 100,
      buttonText: 'Top up',
      buttonVariant: 'outlined',
      stripeID: 'price_1HMvYLG1SnPXHbeof0zfor0v'
    },
    {
      price: '40',
      credits: 1000,
      bonus: 200,
      buttonText: 'Top up',
      buttonVariant: 'outlined',
      stripeID: 'price_1HMvZCG1SnPXHbeoFviTg0YW'
    },
    {
      price: '50',
      credits: 1250,
      bonus: 300,
      description: ['Address pricing:', '1 generic = 2 credits', '1 specific = 3 credits'],
      buttonText: 'Top up',
      buttonVariant: 'outlined',
      stripeID: 'price_1HMvZkG1SnPXHbeoMLIX5RGF'
    },
    {
      price: '65',
      credits: 1625,
      bonus: 400,
      description: ['Address pricing:', '1 generic = 2 credits', '1 specific = 3 credits'],
      buttonText: 'Top up',
      buttonVariant: 'outlined',
      stripeID: 'price_1HMvaOG1SnPXHbeoR0zRDPod'
    }
  ]
}

export const stripe = prodStripe

export const socialMedia = {
  discord: 'discord.gg/MSuAMfy'
}