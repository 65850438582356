import React from 'react'
import { connect } from 'react-redux'
import { Alert as MuiAlert, AlertTitle } from '@material-ui/lab'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

const Alert = ({ alerts }) => alerts !== null && alerts.length > 0 && alerts.map((alert, index) => (
  <Box key={alert.tag} pt={!index ? 8 : 1}>
    <MuiAlert 
      severity={alert.data.severity}
      action={
        alert.data.button && <Button onClick={alert.data.buttonAction} color="inherit" size="large">
          {alert.data.button}
        </Button>
      }
    >
      <AlertTitle>{alert.data.title}</AlertTitle>
      {alert.data.message}
    </MuiAlert>
  </Box>
))
  
const mapStateToProps = state => ({
  alerts: state.alert
})
  
export default connect(mapStateToProps)(Alert)