import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { withStyles } from '@material-ui/styles'
import Pages from '../../assets/pages'
import { Context } from '../../constants'
import { withRouter } from 'react-router-dom'
import firebase from 'firebase/app'
import ReactSwitch from 'react-switch'
import { connect } from 'react-redux'
import { setAlert, removeAlert } from '../../redux/actions/alert'

class InfoView extends React.Component {
  static contextType = Context.UserContext

  constructor(props) {
    super()
    this.state = { checked: false }
    this.handleChange = this.handleChange.bind(this)
    this.history = props.history
  }

  componentDidMount() {
    this.setState({ checked: this.context.agreedTC })
  }

  handleChange(checked) {
    if (this.state.checked !== checked) {
      this.setState({ checked })

      firebase.functions().httpsCallable('acceptTC').call().then(() => {

        this.props.removeAlert('notc')

        this.history.push('/home')
      }).catch(() => {
        this.props.setAlert('erroracceptingtc', {
          severity: 'error',
          message: 'There was an error accepting the terms & conditions'
        })
      })
    }
  }
  render() {
    const { classes } = this.props

    var textToShow = ''
    var titleToShow = ''

    const rightPage = Pages[this.props.type]
    titleToShow = rightPage.title
    textToShow = rightPage.text

    return (
      <div>
        <CssBaseline />
        <Container maxWidth="lg" component="main">
          <Box className={classes.paper} mb={5} pt={10} >
            <Typography component="h1" variant="h2" align="left">
              <Box fontWeight="fontWeightBold" m={0}>
                {titleToShow}
              </Box>
            </Typography>
          </Box>

          {textToShow.split('\n').map((each, index) => {
            return <Box pb={1} key={`box-show-${index}`}>
              <Typography variant="subtitle1">
                {each}
              </Typography>
            </Box>
          })}

          {rightPage.title === 'Terms and Conditions' ? <ReactSwitch
            checkedIcon={false}
            uncheckedIcon={false}
            offColor="#212428"
            offHandleColor="#BF9B30"
            onColor="#212428"
            height={35}
            width={70}
            onHandleColor="#BF9B30"
            checked={this.state.checked}
            disabled={this.state.checked}
            onChange={this.handleChange}
          /> : null}
        </Container>
      </div>
    )
  }

}

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column'
  }
})

const mapActionsToProps = {
  setAlert,
  removeAlert
}

export default connect(null, mapActionsToProps)(withStyles(styles)(withRouter(InfoView)))