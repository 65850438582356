import React from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import Chip from '@material-ui/core/Chip'
import Filter from 'shared4/filter'

export default function FileOrderList(props) {
  const [items, setItems] = React.useState(Filter.all())
  const [checked, setChecked] = React.useState(items.filter(value => {
    return value.checkedByDefault
  }))

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (newChecked.length === 1 && currentIndex !== -1) {
      return
    }

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)


    const filteredItems = items.filter(key => {
      return newChecked.indexOf(key) !== -1
    })

    props.onMoved(filteredItems)
  }


  const onSortEnd = ({ oldIndex, newIndex }) => {
    const moved = arrayMove(items, oldIndex, newIndex)
    setItems(moved)
    props.onMoved(moved.filter(key => {
      return checked.indexOf(key) !== -1
    }))
  }

  const SortableItem = SortableElement(({ value }) => {
    return <li style={{ listStyleType: 'none', display: 'inline-block', marginLeft: '10px', marginRight: '10px', marginTop: '10px' }}>
      <Chip onClick={handleToggle(value)} color={checked.indexOf(value) !== -1 ? 'secondary' : 'primary'} label={value.displayName} />
    </li>
  })

  const SortableList = SortableContainer(({ items }) => {
    return (
      <ul style={{ paddingBottom: '10px', paddingLeft: '0px' }}>

        {items.map((value, index) => (
          <SortableItem key={`item-${value.id}`} index={index} value={value} />
        ))}
      </ul>
    )
  })

  return <SortableList distance={10} axis="xy" items={items} onSortEnd={onSortEnd} />
}