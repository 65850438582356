export default {
  privacy: {
    title: 'Privacy Policy',
    text: `Thank you for choosing to be part of our community at Precision Raffles LLC (“Company”, “we”, “us”, or “our”). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our notice, or our practices with regards to your personal information, please contact us at precision@raffles.com.
When you visit our website armatas.tools, and use our services, you trust us with your personal information. We take your privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Sites and our services.
This privacy notice applies to all information collected through our website (such as armatas.tools), and/or any related services, sales, marketing or events (we refer to them collectively in this privacy notice as the "Services").
This privacy policy was created using Termly’s Privacy Policy Generator.`
  },
  gdpr: {
    title: 'GDPR',
    text: `Your privacy is important to us. It is Precision Raffles' policy to respect your privacy regarding any information we may collect from you across our website, http://armatas.tools and other sites we own and operate.
We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.
We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorised access, disclosure, copying, use or modification.
We don’t share any personally identifying information publicly or with third-parties, except when required to by law.
Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.
You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.
Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.
        `
  },
  terms: {
    title: 'Terms and Conditions',
    text: `If you do not agree with all of these Terms and Conditions, then you are prohibited from using the Site and Services and you must discontinue use immediately. We recommend that you print a copy of these Terms and Conditions for future reference.  
1.2  The supplemental policies set out in Section 1.7 below, as well as any supplemental terms and condition or documents that may be posted on the Site from time to time, are expressly incorporated by reference. 
1.3  We may make changes to these Terms and Conditions at any time. The updated version of these Terms and Conditions will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. You are responsible for reviewing these Terms and Conditions to stay informed of updates. Your continued use of the Site represents that you have accepted such changes.  
This privacy policy was created using Termly’s Privacy Policy Generator.
`
  }
}