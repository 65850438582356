import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'
import StyledButton from '../StyledButton'
import GrayTextTypography from '../common/GrayTextTypography'

const useStyles = makeStyles(() => ({
  paper: {
    background: '#0D0E11'
  },
  grayText: {
    top: '20px'
  },
  footerLink: {
    fontSize: '90%'
  }
}))

export default function Footer() {
  const classes = useStyles()

  const [bothTicked, setBothTicked] = useState(false)
  const [boxChecked, checkBox] = useState(false)
  const [contactEmail, setContactEmail] = useState('')

  const updateBoth = (boxChecked, contactEmail) => {

    function validateEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
      return re.test(String(email).toLowerCase())
    }

    setBothTicked(boxChecked && validateEmail(contactEmail))
  }

  const handleEmailChange = (e) => {
    const contactEmail = e.target.value
    setContactEmail(contactEmail)

    updateBoth(boxChecked, contactEmail)
  }

  const handleBoxChange = (e) => {
    const boxChecked = e.target.checked
    checkBox(boxChecked)

    updateBoth(boxChecked, contactEmail)
  }

  const FancyLink = React.forwardRef((props, ref) => (
    <Link component={RouterLink} to={props.link}>
      <GrayTextTypography ref={ref} className={classes.footerLink}>
        {props.children}
      </GrayTextTypography>
    </Link>
  ))

  return (
    <Box pb={4} pt={10}>
      <Grid container spacing={3}>
        <Grid item xs>
          <Box pb={2}>
            <Typography>
              Tools
            </Typography>
          </Box>

          <FancyLink link="/">
            Address generation
          </FancyLink>

          <Box pt={1}>
            <FancyLink link="/standalone">
              ID generator
            </FancyLink>
          </Box>
        </Grid>
        <Grid item xs>
          <Box pb={2}>
            <Typography>
              Navigation
            </Typography>
          </Box>
          <FancyLink link="/">
            Home
          </FancyLink>
          <Box pt={1} pb={1}>
            <FancyLink link="/terms">
              Terms and Conditions
            </FancyLink>
          </Box>
          <FancyLink link="/privacy">
            Privacy Policy
          </FancyLink>
          <Box pt={1} pb={1}>
            <FancyLink link="/gdpr">
              GDPR
            </FancyLink>
          </Box>
          <FancyLink link="/about">
            About
          </FancyLink>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            Newsletter
          </Typography>
          <Box pt={2} pb={2}>
            <GrayTextTypography variant="caption">
              Subscribe to our newsletter to get the latest updates on this tool, and be the first to grab that new deal!
            </GrayTextTypography>
          </Box>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField onChange={handleEmailChange} value={contactEmail} color="secondary" style={{ flexGrow: 2 }} id="outlined-basic-2" label="E-mail address" variant="outlined" />
            <Box pl={2}>
              <StyledButton
                style={{ height: '100%' }}
                type="submit"
                variant="contained"
                color="secondary"
                text="Sign up"
                disabled={!bothTicked}
                className={classes.submit}
              >
              </StyledButton>
            </Box>
          </div>
          <Box pt={1}>
            <FormControlLabel control={<Checkbox checked={boxChecked} onChange={handleBoxChange} name="checkedC" />} label={<GrayTextTypography variant="subtitle1" style={{ fontSize: '90%' }}>I accept processing my e-mail address with the aim of receiving information about Armatas Tools, including marketing and sales information.</GrayTextTypography>} />
          </Box>
        </Grid>
        <Grid style={{ position: 'relative' }} item xs>
          <img style={{ position: 'absolute', right: 0, bottom: 0 }} alt="" width="40%" src="https://i.imgur.com/EE4sorU.png" />
        </Grid>
      </Grid>
      <Box pt={4}>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © Precision Raffles SRL, Bucharest, Romania'}
          {' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Box>
    </Box >
  )
}