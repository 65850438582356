import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TimeAgo from 'react-timeago'
import MergeToolList from './file_order/MergeToolList'
import Box from '@material-ui/core/Box'
import csv from 'csv-parser'
import GetAppIcon from '@material-ui/icons/GetApp'
import DeleteIcon from '@material-ui/icons/Delete'
import CircularProgress from '@material-ui/core/CircularProgress'
import MergeTypeIcon from '@material-ui/icons/MergeType'
import ErrorIcon from '@material-ui/icons/Error'
import Tooltip from '@material-ui/core/Tooltip'

class ReportItem extends React.Component {

  constructor(props) {
    super(props)

    this.downloadDecoded = this.downloadDecoded.bind(this)
    this.openMergeTool = this.openMergeTool.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.getPopoverRef = this.getPopoverRef.bind(this)
    this.deleteReport = this.deleteReport.bind(this)

    this.popoverRef = React.createRef()

    const data = props.report.data()
    const dateCreated = data['dateCreated'].toDate().toString()

    var radius = data['radius']
    if (radius === undefined) {
      radius = 'N/A'
    }

    var theStatus = data['status']
    if (theStatus === undefined) {
      theStatus = 'done'

    }

    this.state = {
      addressCount: data['addressCount'],
      failReason: data['failReason'],
      dateCreated: dateCreated,
      encoded: data['encoded'],
      originalStreet: data['original'],
      radius: radius,
      key: props.mKey,
      subscribed: props.subscribed,
      report: props.report,
      status: theStatus
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const data = nextProps.report.data()
    const dateCreated = data['dateCreated'].toDate().toString()

    var radius = data['radius']
    if (radius === undefined) {
      radius = 'N/A'
    }

    var theStatus = data['status']
    if (theStatus === undefined) {
      theStatus = 'done'

    }

    this.setState({
      addressCount: data['addressCount'],
      dateCreated: dateCreated,
      encoded: data['encoded'],
      originalStreet: data['original'],
      radius: radius,
      key: nextProps.mKey,
      subscribed: nextProps.subscribed,
      report: nextProps.report,
      status: theStatus
    })
  }

  downloadDecoded() {
    const decoded = this.state.encoded

    const element = document.createElement('a')
    const file = new Blob([decoded], { type: 'text/plain' })
    element.href = URL.createObjectURL(file)
    element.download = this.state.originalStreet + '.csv'
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
  }

  //columns should be array

  openMergeTool(e) {
    this.setState({ anchorEl: e.currentTarget, convertedList: [] })

    // eslint-disable-next-line no-undef
    var Readable = require('stream').Readable

    var s = new Readable()
    s.push(this.state.encoded)    // the string you want
    s.push(null)      // indicates end-of-file basically - the end of the stream

    const results = []
    s.pipe(csv())
      .on('data', (data) => results.push(data))
      .on('end', () => {

        var converted = {}
        for (var i = 0; i < results.length; i++) {
          const current = results[i]
          const keys = Object.keys(current)
          for (var j = 0; j < keys.length; j++) {
            const curKey = keys[j]
            if (i === 0) {
              converted[curKey] = [curKey]
            }
            converted[curKey].push(current[curKey])
          }
        }
        converted = Object.values(converted)

        const maybeFinal = converted.map(value => {
          const toRet = Object.assign({}, value)
          toRet.source = 'Report'
          return toRet
        })

        this.setState({
          convertedList: maybeFinal
        })
      })

  }

  handleClose() {
    this.setState({ anchorEl: undefined })
  }

  getPopoverRef() {
    return this.popoverRef.current
  }

  deleteReport() {
    const ref = this.state.report.ref
    ref.delete()
  }

  render() {
    const status = this.state.status
    const failReason = this.state.failReason

    const failIconWithHint = () => {
      var goodFailReason = failReason
      if (goodFailReason === undefined || goodFailReason === null || goodFailReason.length === 0) {
        goodFailReason = 'Unknown error occured, please contact support!'
      }
      return <Box pl={1} >
        <Tooltip title={goodFailReason} aria-label="add">
          <ErrorIcon style={{ color: '#A63232' }} />
        </Tooltip>
      </Box>
    }

    return (
      <TableRow key={this.state.key}>
        <TableCell className="leftRoundedCell" component="th" scope="row">
          <Box>
            {this.state.originalStreet}
          </Box>
        </TableCell>
        <TableCell style={{ color: '#5D6C76' }}><TimeAgo date={this.state.dateCreated} /></TableCell>
        <TableCell style={{ color: '#5D6C76' }}>{this.state.addressCount}</TableCell>
        <TableCell style={{ color: '#5D6C76' }}>{this.state.radius}</TableCell>

        <TableCell style={{ color: '#5D6C76' }}>
          {status === 'done' && <IconButton onClick={this.downloadDecoded} aria-label="download report" component="span">
            <GetAppIcon style={{ color: '#5D6C76' }} />
          </IconButton>}

          {status === 'processing' && <Box pl={1} ><CircularProgress size={30} color="secondary" /> </Box>}
          {status === 'failed' && failIconWithHint()}

        </TableCell>

        {this.props.subscribed && <TableCell>
          {status === 'done' && <IconButton onClick={this.openMergeTool} aria-label="merge tool" component="span">
            <MergeTypeIcon style={{ color: '#5D6C76' }} />
          </IconButton>}

          {status === 'processing' && <Box pl={1} ><CircularProgress size={30} color="secondary" /> </Box>}
          {status === 'failed' && failIconWithHint()}
        </TableCell>}

        <TableCell style={{ color: '#5D6C76' }} className="rightRoundedCell">
          {(status === 'done' || status === 'failed') && <IconButton onClick={this.deleteReport} aria-label="delete report" component="span">
            <DeleteIcon style={{ color: '#5D6C76' }} />
          </IconButton>}


        </TableCell>

        <Popover
          ref={this.popoverRef}
          open={Boolean(this.state.anchorEl)}
          anchorEl={this.state.anchorEl}
          className="mergePopover"
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MergeToolList reportName={this.state.originalStreet} helperContainer={this.getPopoverRef} options={this.state.convertedList} />
        </Popover>
      </TableRow>
    )
  }
}

export default ReportItem