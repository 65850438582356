import React, { useContext, useState, useEffect } from 'react'
import * as MatUI from '@material-ui/core'
import { Context } from '../../constants'
import { Link as RouterLink } from 'react-router-dom'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import GrayTextTypography from '../common/GrayTextTypography'
import AddIcon from '@material-ui/icons/Add'
import firebase from '../../firebase/firebase'
import { connect } from 'react-redux'
import { removeAlerts } from '../../redux/actions/alert'
import logo from '../../assets/logo.png'
import raffle from '../../assets/raffle-rotated.png'

const Header = ({ removeAlerts }) => {

  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const [popperWidth, setPopperWidth] = useState(0)

  //nu ai this in functii, tre sa o faci asa
  const user = useContext(Context.UserContext)

  const redTheme = createMuiTheme({ //FIXME remove this and all other appearances
    palette: {
      primary: {
        main: '#FFFFFF'
      },
      secondary: {
        main: '#a11d1d'
      }
    },
  })

  const googleLogin = (e) => {
    e.preventDefault()

    firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider()).catch(err => {
      console.log(err)
      //this.setState({ error: err.message })
    })
  }

  const currentUser = firebase.auth().currentUser

  const openMenuRef = React.createRef()

  const openMenuHard = (e) => {
    setMenuAnchorEl(e.currentTarget)
  }

  const closeMenuHard = () => {
    setMenuAnchorEl(null)
  }

  const logOutUser = () => {
    closeMenuHard()
    firebase.auth().signOut().then(removeAlerts())
  }

  useEffect(() => {
    if (openMenuRef.current != null) {
      setPopperWidth(openMenuRef.current.clientWidth)
    }
  }, [openMenuRef])

  var subbed = false
  if (user) {
    subbed = user.subscribed
  }

  return (
    <MatUI.AppBar style={{ background: 'transparent', boxShadow: 'none', position: 'absolute' }} position="static">
      <MatUI.Toolbar>
        <MatUI.Box pl={4}>

          <MatUI.Button style={{ backgroundColor: 'transparent' }} color="inherit" component={RouterLink} to="/">
            <img className="logo" src={logo} alt=""/>
            <MatUI.Typography style={{ color: 'white' }} variant="h6">
              ArMaTaS Tools
            </MatUI.Typography>
          </MatUI.Button>
        </MatUI.Box>
        <div className="emptySpace">
        </div>

        <MatUI.Box>
          <MatUI.Button color="inherit" component={RouterLink} to="/">
            {user ? 'Address Generator' : 'Home' }
          </MatUI.Button>
        </MatUI.Box>
        {subbed && <MatUI.Box pl={3} pr={3}>
          <MatUI.Button color="inherit" component={RouterLink} to="/standalone">
            ID Generator
          </MatUI.Button>
        </MatUI.Box>}

        <MatUI.Box pl={subbed ? 0 : 3} pr={3}>
          <MatUI.Button color="inherit" component={RouterLink} to="/about">
            About
          </MatUI.Button>
        </MatUI.Box>

        <MatUI.Box pl={subbed ? 0 : 3} pr={3}>
          <MatUI.Button color="inherit" component={RouterLink} to="/help">
            Help
          </MatUI.Button>
        </MatUI.Box>

        <MatUI.Box pl={subbed ? 0 : 3} pr={3}>
          <MatUI.Button color="inherit" component={RouterLink} to="/faq">
            FAQ
          </MatUI.Button>
        </MatUI.Box>

        {currentUser != null && <MatUI.Box style={{ display: 'flex', alignItems: 'center' }} pr={3}>
          <MatUI.Button ref={openMenuRef} onClick={openMenuHard}>
            <MatUI.Avatar alt={currentUser.displayName} src={currentUser.photoURL} />
            <MatUI.Box pl={2} pr={1}>
              <MatUI.Typography component="h1" variant="h6">
                <div style={{ display: 'grid', gridTemplateColumns: '1fr', fontSize: '90%' }}>
                  <MatUI.Box style={{ color: 'white' }}>
                    {currentUser.displayName}
                  </MatUI.Box>
                </div>
              </MatUI.Typography>
              <GrayTextTypography style={{ fontSize: '85%', textAlign: 'left' }} component="h1" variant="h6">
                {user && user.credits} <img className="raffle" src={raffle} alt=""/>
              </GrayTextTypography>
            </MatUI.Box>
            {!subbed && <MatUI.Box pl={2} pr={2}>
              <ThemeProvider theme={redTheme} >
                <MatUI.Badge badgeContent={1} color="secondary">
                </MatUI.Badge>
              </ThemeProvider>
            </MatUI.Box>}
          </MatUI.Button>
          <MatUI.Fab component={RouterLink} to="/pricing" style={{ transform: 'scale(0.8)' }} size="small" color="secondary" aria-label="add">
            <AddIcon />
          </MatUI.Fab>
          <MatUI.Popper style={{ width: popperWidth, backgroundColor: '#191A1F' }} open={Boolean(menuAnchorEl)} anchorEl={menuAnchorEl} role={undefined} transition disablePortal>
            <MatUI.ClickAwayListener onClickAway={closeMenuHard}>
              <MatUI.MenuList id="menu-list-grow" >
                <MatUI.MenuItem style={{ height: '50px' }} component={RouterLink} to="/pricing" onClick={closeMenuHard}>
                  Store
                  <MatUI.Box pl={2} pb={2}>
                    <ThemeProvider theme={redTheme} >
                      <MatUI.Badge badgeContent={1} color="secondary" invisible={subbed}>
                      </MatUI.Badge>
                    </ThemeProvider>
                  </MatUI.Box>
                </MatUI.MenuItem>
                <MatUI.MenuItem style={{ height: '50px' }} onClick={logOutUser}>Logout</MatUI.MenuItem>
              </MatUI.MenuList>
            </MatUI.ClickAwayListener>
          </MatUI.Popper>
        </MatUI.Box>}

        <MatUI.Box pr={3}>
          {currentUser == null && <MatUI.Button onClick={googleLogin} variant="contained" color="secondary">Sign In</MatUI.Button>}
        </MatUI.Box>


      </MatUI.Toolbar>
    </MatUI.AppBar >
  )
}

const mapActionsToProps = {
  removeAlerts
}

export default connect(null, mapActionsToProps)(Header)
