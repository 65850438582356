import React, { useContext, useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import StyledButton from '../StyledButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Context, stripe } from '../../constants'
import Badge from '@material-ui/core/Badge'
import Tooltip from '@material-ui/core/Tooltip'
import firebase from '../../firebase/firebase'
import { useStripe } from '@stripe/react-stripe-js'
import raffle from '../../assets/raffle-rotated.png'

const useStyles = makeStyles(() => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  cardPricing: {
    display: 'flex',
    alignItems: 'baseline'
  },
  paperSecond: {
    background: '#1A1B1F'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  loadingWrapper: {
    position: 'relative',
  },
  submit: {

  }
}))

const subscription = {
  title: 'Subscription',
  monthly: true,
  price: '50',
  credits: 1000,
  description: ['Pay as you go pricing', '+ Merge Tool', '+ ID generators'],
  buttonText: 'Subscribe',
  buttonVariant: 'outlined'
}
export default function Subscription(props) {
  const classes = useStyles()

  const user = useContext(Context.UserContext)

  const redTheme = createMuiTheme({
    palette: {
      secondary: {
        main: '#a11d1d'
      }
    },
  })

  const realStripe = useStripe()

  const [subscribeRunning, setSubscribeRunning] = useState(false)
  const [topupRunning, setTopupRunning] = useState(false)

  const handleNoUser = () => {
    props.handleNoUser()
  }

  const runSubscribe = () => {
    if (!user) {
      handleNoUser()
      return
    }

    setSubscribeRunning(true)
    firebase.functions().httpsCallable('subscriptionCheckout')().then(result => {
      realStripe.redirectToCheckout({ sessionId: result.data.sessionID })
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      setSubscribeRunning(false)
    })
  }
  const runTopup = () => {
    if (!user) {
      handleNoUser()
      return
    }

    const stripeID = stripe.specialTier.stripeID
    setTopupRunning(true)
    firebase.functions().httpsCallable('topupCheckout')({ priceID: stripeID }).then(result => {
      realStripe.redirectToCheckout({ sessionId: result.data.sessionID })
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      setTopupRunning(false)
    })
  }

  const [unsubscribeRunning, setUnsubscribeRunning] = useState(false)
  const runPortal = () => {
    setUnsubscribeRunning(true)
    firebase.functions().httpsCallable('openPortal')().then(result => {
      const url = result.data.url
      window.location = url
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      setUnsubscribeRunning(false)
    })
  }

  const isSubscribed = user && user.subscribed && firebase.auth().currentUser

  const defaultSub = (user && user.stripeCouponApplied) ? 20 : 0
  const [subscriptionReduction, setSubReduction] = React.useState(defaultSub)

  React.useEffect(() => {
    if (user === null) {
      return
    }
    firebase.functions().httpsCallable('getDiscountedPrice')().then(res => {
      console.log('Result is: ')
      console.log(res)
      const tData = res.data
      if(tData) {
        const am = tData.discountPercentage
        if (am) {
          setSubReduction(am)
        }
      }
    }).catch(e => {
      console.log(e)
    })

    console.log('Done')
  }, [user])

  return (
    <Grid container spacing={5} alignItems="flex-end" style={{ justifyContent: 'center' }}>

      <Grid xs={12} sm={12} md={12} lg={6} style={{ maxWidth: '400px' }} item key="payasyougo">
        <Card className={classes.paperSecond}>
          <CardContent>
            <div style={{ display: 'flex' }}>
              <Typography variant="h6" className={classes.title} color="textSecondary">
                Pay-as-you-go
              </Typography>
            </div>
            <Box pt={2} pb={1} className={classes.cardPricing}>
              <Typography variant="h4" color="textPrimary">
                £15
              </Typography>
            </Box>
            <Box pb={5}>
              <Typography style={{ fontSize: '90%' }} color="textSecondary">
                300 <img className="raffle" src={raffle} alt=""/>
              </Typography>
            </Box>
            <ul>
              <Typography component="li" variant="subtitle1" key="line0">
                Address pricing:
              </Typography>
              <Typography component="li" variant="subtitle1" key="line1">
                1 generic = 2 <img className="raffle" src={raffle} alt=""/>
              </Typography>
              <Typography component="li" variant="subtitle1" key="line2">
                1 specific = 3 <img className="raffle" src={raffle} alt=""/>
              </Typography>
            </ul>
            <Box pt={4}>
              <div className={classes.loadingWrapper}>
                <Tooltip title={isSubscribed ? 'You are subscribed, so you can find better deals below!' : ''}>
                  <div>
                    <StyledButton
                      variant="contained"
                      color="secondary"
                      fullWidth
                      text="Top up"
                      disabled={subscribeRunning || topupRunning || isSubscribed}
                      onClick={runTopup}
                      className={classes.submit}
                    >
                    </StyledButton>
                  </div>
                </Tooltip>
                {topupRunning && <CircularProgress color="secondary" size={24} className={classes.buttonProgress} />}
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={6} style={{ maxWidth: '400px' }} item key={subscription.title}>
        <Card className={classes.paperSecond}>
          <CardContent>
            <div style={{ display: 'flex' }}>
              <Typography variant="h6" className={classes.title} color="textSecondary">
                {subscription.title}
              </Typography>
              {user && !user.subscribed && <Box pl={2}>
                <ThemeProvider theme={redTheme}>
                  <Badge color="secondary" badgeContent={1}>
                  </Badge>
                </ThemeProvider></Box>}

            </div>
            <Box pt={2} pb={1} className={classes.cardPricing}>
              <Typography variant="h4" color="textPrimary">
              £{!subscriptionReduction ? subscription.price : (subscription.price - subscriptionReduction * subscription.price / 100)}
              </Typography>
              <Typography style={{ display: subscription.monthly ? 'flex' : 'none' }} variant="h6" color="textSecondary">
                /2mo
              </Typography>
            </Box>
            <Box pb={5}>
              <Typography style={{ fontSize: '90%' }} color="textSecondary">
                {subscription.credits} <img className="raffle" src={raffle} alt=""/>
              </Typography>
            </Box>
            <ul>
              {subscription.description.map((line) => (
                <Typography component="li" variant="subtitle1" key={line}>
                  {line}
                </Typography>
              ))}
            </ul>
            <Box pt={4}>
              {!isSubscribed && <div className={classes.loadingWrapper}>
                <StyledButton
                  variant="contained"
                  color="secondary"
                  fullWidth
                  text="Subscribe"
                  disabled={subscribeRunning || topupRunning}
                  onClick={runSubscribe}
                  className={classes.submit}
                >
                </StyledButton>
                {subscribeRunning && <CircularProgress color="secondary" size={24} className={classes.buttonProgress} />}
              </div>}
              {isSubscribed && <div className={classes.loadingWrapper}>
                <ThemeProvider theme={redTheme}>
                  <StyledButton
                    variant="contained"
                    color="secondary"
                    text="Cancel Subscription"
                    onClick={runPortal}
                    fullWidth
                    disabled={unsubscribeRunning || topupRunning}
                    className={classes.submit}
                  >
                  </StyledButton>
                </ThemeProvider>
                {unsubscribeRunning && <CircularProgress color="secondary" size={24} className={classes.buttonProgress} />}
              </div>}
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}