import { SET_ALERT, REMOVE_ALERT, REMOVE_ALERTS } from '../actions/constants'

const alert = (prevState, action) => {

  let count = 0

  if (!prevState) prevState = []

  const { type, payload } = action

  switch (type) {
    case SET_ALERT:
        prevState.forEach(alert => alert.tag === payload.tag && count++)

        if(!count) {
            return [...prevState, payload]
        } 
        return prevState
    case REMOVE_ALERT:
        return prevState.filter(alert => alert.tag !== payload)
    case REMOVE_ALERTS:
        return []
    default:
        return prevState
  }
}

export default alert