import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import parse from 'autosuggest-highlight/parse'
import throttle from 'lodash/throttle'
import ReactDOM from 'react-dom'


function loadScript(src, position, id) {
  if (!position) {
    return
  }

  const script = document.createElement('script')
  script.setAttribute('async', '')
  script.setAttribute('id', id)
  script.src = src
  position.appendChild(script)
}

const autocompleteService = { current: null }

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  popper: {
    backgroundColor: '#40434D'
  }
}))

export default function GoogleMaps(props) {
  const classes = useStyles()
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState([])
  const loaded = React.useRef(false)

  var autocompleteShit = null

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyDqJGitiyx5md42noONV1KGS5ZtnEEK4Y0&libraries=places',
        document.querySelector('head'),
        'google-maps',
      )
    }

    loaded.current = true
  }

  const handleChange = (event) => {
    setInputValue(event.target.value)
    props.aa(event)
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback)
      }, 200),
    [],
  )

  async function sleep(msec) {
    return new Promise(resolve => setTimeout(resolve, msec))
  }

  async function waitAndSet() {
    await sleep(1)

    // eslint-disable-next-line react/no-find-dom-node
    const name = ReactDOM.findDOMNode(autocompleteShit)
    if (name != null) {
      const rName = name.children[0].childNodes[1].childNodes[0].value

      const nE = { target: { name: 'location', value: rName } }
      props.aa(nE)
    } else {
      // Didn't react to avoid crash
    }
  }

  const filter = createFilterOptions()

  // eslint-disable-next-line no-unused-vars
  const onClose = (event) => {
    waitAndSet()
  }

  React.useEffect(() => {
    let active = true

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === '') {
      setOptions([])
      return undefined
    }

    fetch({ input: inputValue, types: ['address'] }, (results) => {
      if (active) {
        setOptions(results || [])
      }
    })

    return () => {
      active = false
    }
  }, [inputValue, fetch])

  var copiedProps = {}
  Object.assign(copiedProps, props)
  delete copiedProps.aa

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) => (typeof option === 'string' ? option : (option.description !== undefined ? option.description : option.inputValue))}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)

        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            title: `Add "${params.inputValue}"`,
          })
        }

        return filtered
      }}
      options={options}
      onClose={onClose}
      ref={(input) => { autocompleteShit = input }}
      autoComplete
      autoHighlight={true}
      freeSolo
      includeInputInList
      classes={{
        popper: classes.popper, // class name, e.g. `classes-nesting-root-x`
        listbox: classes.popper
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Add a location"
          variant="outlined"
          fullWidth
          onChange={handleChange}
          {...copiedProps}
        />
      )}
      renderOption={(option) => {
        if (option.structured_formatting === undefined) {
          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>

                {option.title}

                <Typography variant="body2" color="textSecondary">
                  {'Use this for post codes!'}
                </Typography>
              </Grid>
            </Grid>)
        }
        const matches = option.structured_formatting.main_text_matched_substrings
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        )

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        )
      }}
    />
  )
}