import React, { useState, useContext } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import firebase from '../../firebase/firebase'
import { useStripe } from '@stripe/react-stripe-js'
import { Context, stripe } from '../../constants'
import raffle from '../../assets/raffle.png'
import CardActionArea from '@material-ui/core/CardActionArea'

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  cardPricing: {
    display: 'flex',
    alignItems: 'baseline'
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  paperFirst: {
    background: '#0D0E11'
  },
  paperSecond: {
    background: '#1A1B1F'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -25,
    marginLeft: -25,
  },
  loadingWrapper: {
    position: 'relative',
  },
  submit: {

  }
}))

export default function Pricing() {
  const classes = useStyles()

  const realStripe = useStripe()

  const [topRunning, setTopRunning] = useState(false)
  const [activeKey, setActiveKey] = useState(null)

  const greenTheme = createMuiTheme({
    palette: {
      secondary: {
        main: '#00FF00'
      }
    },
  })

  const runTopup = (credits) => {
    const tappedPrice = stripe.tiers.filter(each => each.credits === credits)[0]
    setActiveKey(tappedPrice.credits)

    setTopRunning(true)
    firebase.functions().httpsCallable('topupCheckout')({ priceID: tappedPrice.stripeID }).then(result => {
      realStripe.redirectToCheckout({ sessionId: result.data.sessionID })
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      setTopRunning(false)
    })
  }

  const user = useContext(Context.UserContext)
  const isSubscribed = user && user.subscribed && firebase.auth().currentUser

  const itemsDisabled = topRunning || !isSubscribed

  return (
    <Grid container spacing={10} alignItems="flex-end" style={{ justifyContent: 'center' }}>
      {stripe.tiers.map((tier) => (
        // Enterprise card is full width at sm breakpoint
        <Grid sm={8} md={4} style={{ maxWidth: '400px' }} item key={tier.price}>
          <Tooltip title={isSubscribed ? '': 'Get access to these awesome deals by subscribing!'} aria-label="add">
            <Card className={classes.paperFirst}>
              <CardActionArea onClick={() => runTopup(tier.credits)} disabled={itemsDisabled}>
                <CardContent>
                  <Box className={classes.loadingWrapper}>
                    <Box style={{ opacity: (activeKey === tier.credits || itemsDisabled) ? '50%' : '100%' }}>
                      <Box style={{ justifyContent: 'center' }} pb={1} className={classes.cardPricing}>
                        <Typography variant="h5" color="textPrimary">
                          £{tier.price} GBP
                        </Typography>
                      </Box>
                      <Box style={{ display: 'flex', justifyContent: 'center' }}>
                        <img style={{ width: '40%' }} src={raffle} alt="" />
                      </Box>
                      <Box>
                        <Typography style={{ fontSize: '180%', textAlign: 'center' }} color="textSecondary">
                          {tier.credits} credits
                        </Typography>
                        <ThemeProvider theme={greenTheme}>
                          <Typography style={{ fontSize: '150%', textAlign: 'center' }} color={tier.bonus > 0 ? 'secondary' : 'textSecondary'}>
                            + {tier.bonus} bonus credits
                          </Typography>
                        </ThemeProvider>
                      </Box>
                      <Box>
                        <Typography style={{ fontSize: '200%', textAlign: 'center' }} color="textPrimary">
                          {tier.credits + tier.bonus}
                        </Typography>
                      </Box>
                    </Box>
                    {activeKey === tier.credits && <CircularProgress color="secondary" size={50} className={classes.buttonProgress} />}
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Tooltip>
        </Grid>
      ))}
    </Grid>
  )
}