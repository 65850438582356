import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { withStyles } from '@material-ui/styles'

class About extends React.Component {

  render() {
    const { classes } = this.props

    return (
      <div>
        <CssBaseline />
        <Container maxWidth="lg" component="main">
          <Box className={classes.paper} mb={5} pt={10} >
            <Typography component="h1" variant="h2" align="left">
              <Box fontWeight="fontWeightBold" m={0}>
                About
              </Box>
            </Typography>
          </Box>

          <Box pb={1} key={'box-show-0'}>
            <Typography variant="subtitle1">
              ArMaTaS Tools is a set of state-of-the-art algorithms that focus on generating hundreds of
              real addresses within minutes. We also offer a lot of flexibility by covering all major
              countries including Australia, Canada, and the USA. We know how hard it is to generate
              hundreds of names, and phone numbers for your addresses, especially if it is not your native
              country. Rest, assure ArMaTaS tools will provide you with country-specific names and phone
              numbers even for countries like Iceland.
            </Typography> 
          </Box>

          <Box pb={1} key={'box-show-1'}>
            <Typography variant="subtitle1">
              Our team and community are here to provide you with the best tools and advice for
              securing that W. Our expert chefs will answer any sneaker related questions, from the best
              available proxies to redirecting your packages. Our <a href='https://discord.com/invite/MSuAMfy' id='social'>Discord channel</a> 
              is home to a vibrant community where you can exchange information and find the best setup
              for your raffle bot. You can also find great deals on proxies, partnerships with raffle bots,
              hand-made emails, and buy/sell your raffle wins for hustle free money.
            </Typography>
          </Box>

          <Box pb={1} key={'box-show-2'}>
            <Typography variant="subtitle1">
              To keep in touch, subscribe to our <a href='https://twitter.com/armatastools' id='social'>Twitter</a> page
              We all know trust is gained hard. Here at ArMaTaS Tools, we strive to offer you the most
              professional services in the game. Below you will find some testimonies from our users. As
              for our proven success, you can check or <a href='https://twitter.com/armatastools' id='social'>Twitter</a> account.
            </Typography>
          </Box>
        </Container>
      </div>
    )
  }
}

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column'
  }
})

export default (withStyles(styles)(About))