import fakeryTools from 'shared4'

export const countryList = function() {
  return Object.keys(fakeryTools.faker_dict)
}

export const generate = function (country, count, settings) {
  if (fakeryTools.faker_dict[country] === undefined) {
    return Promise.reject('Invalid country!')
  }
  if (count > 1000) {
    return Promise.reject('You can\'t generate more than 1000 profiles at the same time!')
  }
   
  var toRet = ''
  settings.forEach(each => {
    toRet += each.displayName + ','
  })
  toRet += '\n'
    
  /* eslint-disable */
    for (var i = 0; i < count; i++) {
        // First we get the full name (8) first name (9) and last name (10) filters
        const fullName = settings.find(each => each.id === 8)
        const firstName = settings.find(each => each.id === 9)
        const lastName = settings.find(each => each.id === 10)

        // console.log("Filters:")
        // console.log(fullName)
        // console.log(firstName)
        // console.log(lastName)

        //Now if the hack should be enabled, we create the nastyFullName var containing the filter to be used
        const nastyHackEnabled = fullName !== undefined && (firstName !== undefined || lastName !== undefined)
        const nastyFullName = nastyHackEnabled ? fullName.filterElement(country, {}) : undefined

        //Nasty fix end

        //console.log("Values: "+nastyHackEnabled+' '+nastyFullName)

        settings.forEach(each => {
          if(nastyFullName !== undefined) { // This is where we apply the modifier hack
            //console.log("Hack activated for id "+each.id)
              if (each.id === 8) {
                toRet += nastyFullName + ","
                return
              }
              if (each.id === 9) {
                toRet += nastyFullName.split(" ")[0] + ","
                return
              }
              if (each.id === 10) {
                toRet += nastyFullName.split(" ")[1] + ","
                return
              }
          }
            toRet += each.filterElement(country, null) + ", "
        })
        toRet += "\n"
    }
    /* eslint-enable */
  return Promise.resolve(toRet)
}
