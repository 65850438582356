import { SET_ALERT, REMOVE_ALERT, REMOVE_ALERTS } from './constants'

// Set alert action
export const setAlert = (tag, data) => dispatch => {

  // Display alert
  dispatch({
    type: SET_ALERT,
    payload: { tag, data }
  })

  if(data.timer) {

    // Remove alert
    setTimeout(() => dispatch({
      type: REMOVE_ALERT,
      payload: tag
    }), data.timer)
  }
}

// Remove alert action
export const removeAlert = (tag) => dispatch => {

  // Remove alert
  dispatch({
    type: REMOVE_ALERT,
    payload: tag
  })
}

// Remove all alerts action
export const removeAlerts = () => dispatch => {

  // Remove all alerts
  setTimeout(() => dispatch({
    type: REMOVE_ALERTS
  }), 1000)
}
