import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'
import Firebase, { firebaseConfig } from './firebase/firebase'
import Header from './components/common/Header'
import Login from './views/Login'
import Homepage from './views/Home'
import StandAlone from './views/StandAlone'
import Help from './views/Help'
import Store from './views/Pricing'
import InfoView from './views/Info'
import Container from '@material-ui/core/Container'
import Footer from './components/common/Footer'
import { Context, stripe } from './constants'
import logo from './assets/logo.png'
import './styles/main.css'
import 'typeface-roboto'
import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route
} from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Provider } from 'react-redux'
import store from './redux/store'
import Alert from './components/common/Alert'
import About from './views/About'
import Faq from './views/Faq'

//Dev: pk_test_51GtkADG1SnPXHbeoGkW3JtLIljjVKJWlgWM0jY8TZzpWrfGzKgF5PuacCs9Bvf82euFm7h0FqxsZLzaqZqF7q9mV00doiB9lkv
//Prod: pk_live_fzE92NLRDeEgsCrs10NNt1WH00znEEF2di
const stripePromise = loadStripe(stripe.stripeAPIKey)

class ProtectedRoute extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      authed: props.authenticated
    }

  }

  static getDerivedStateFromProps(props) {
    return { authed: props.authenticated }
  }

  render() {
    const { myRender, ...props } = this.props
    return (
      <Route
        {...props}
        render={props => (
          this.state.authed ?
            myRender(props) :
            <Redirect to='/login' />
        )}
      />
    )
  }
}

const firebase = Firebase.initializeApp(firebaseConfig)

class App extends React.Component {
  constructor(props) {
    super(props)

    this.routerRef = React.createRef()
    this.state = { firebase: firebase, userListener: null, userContext: null, loaded: false }
    firebase.analytics()


    this.darkTheme = createMuiTheme({
      palette: {
        type: 'dark',
        primary: {
          main: '#1A1B1F'
        },
        secondary: {
          main: '#BF9B30',
          contrastText: '#FFFFFF'
        },
        cancel: {
          main: '#f44336'
        },
        background: {
          default: '#111217'
        }
      },
      typography: {
        button: { //152 * 24
          padding: '0 100px',
          textTransform: 'none'
          //fontSize: "60%"
        },
        fontFamily: [
          'Montserrat', 'sans-serif'
        ].join(','),
      }
    })

    this.addUserListener = this.addUserListener.bind(this)
    this.removeUserListener = this.removeUserListener.bind(this)
  }

  isLoggedIn() {
    const firebase = this.state.firebase

    if (firebase == null) {
      return false
    }
    const currentUser = firebase.auth().currentUser
    return currentUser !== null && currentUser !== undefined
  }

  addUserListener(user) {
    const res = this.state.firebase.firestore().collection('/users').doc(user.uid).onSnapshot(doc => {
      const data = doc.data()

      if (data === undefined) {
        return
      }

      const remainingCredits = data['remainingCredits']

      var subscriptionEnd = data['subscriptionEnd']
      const agreedTC = data['agreedTC'] !== undefined ? data['agreedTC'] : false
      const strikes = data['strikes']
      const stripeCouponApplied = data['stripeCouponApplied'] !== undefined ? data['stripeCouponApplied'] : false

      if (subscriptionEnd !== undefined) {
        subscriptionEnd = subscriptionEnd.toDate()
      }
      firebase.analytics().setUserId(doc.ref)

      this.setState({
        userContext: {
          name: user.displayName,
          credits: remainingCredits,
          subscriptionEnd: subscriptionEnd,
          photo: user.photoURL,
          stripeCouponApplied: stripeCouponApplied,
          agreedTC: agreedTC,
          strikes,
          subscribed: (subscriptionEnd !== undefined && (subscriptionEnd > new Date()))
        },
        loggedIn: true,
        loaded: true
      })

    })
    this.setState({ userListener: res })
  }

  removeUserListener() {
    const maybeListener = this.state.userListener
    if (maybeListener) {
      maybeListener()
    }
    this.setState({ userListener: null, userContext: null })
  }

  componentDidMount() {
    this.state.firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        this.addUserListener(user)
      } else {
        this.setState({ loggedIn: false, loaded: true })
        this.removeUserListener()
      }
    }.bind(this)).bind(this)


    const router = this.routerRef.current
    const history = router.history
    history.listen((location) => {
      var path = location.pathname
      if (path === '/') {
        if (this.state.loggedIn) {
          path = '/home'
        } else {
          path = '/login'
        }
      }

      firebase.analytics().logEvent('page_view', {
        name: path
      })
    })
  }

  render() {

    const textPages = ['privacy', 'gdpr', 'terms']
    const textRoute = ((props) => {
      return (
        <Route key={props.path} path={'/' + props.path}>
          <InfoView type={props.path} />
        </Route>
      )
    })

    return (
      <Provider store={store}>
        <Router ref={this.routerRef}>
          <Elements stripe={stripePromise}>
            <Context.UserContext.Provider value={this.state.userContext}>
              <MuiThemeProvider theme={this.darkTheme}>
                <div style={{ width: '100%' }}>
                  <img style={{ opacity:0.1, zIndex: -1, top: '200px', position: 'absolute', width: '30%', height: 'auto', left: '50%', transform:'translateX(-50%)' }} className="logo" src={logo} alt="" />
                  {this.state.loaded && <div className="App" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <Header />
                    <Alert />
                    <div style={{ flexGrow: 2 }}>
                      <Switch>
                        <Route path='/login'>
                          {!this.state.loggedIn ? <Login /> : <Redirect to="/" />}
                        </Route>
                        <Route path='/standalone'>
                          {!this.state.loggedIn ? <Redirect to="/" /> : <StandAlone />}
                        </Route>
                        <Route path='/pricing'>
                          {!this.state.loggedIn ? <Redirect to="/" /> : <Store />}
                        </Route>
                        <Route path='/help'>
                          {!this.state.loggedIn ? <Redirect to="/" /> : <Help />}
                        </Route>
                        {
                          textPages.map(each => {
                            return textRoute({ path: each })
                          })
                        }
                        <Route path='/about'>
                          <About />
                        </Route>
                        <Route path='/faq'>
                          <Faq />
                        </Route>
                        <ProtectedRoute loggedIn={this.state.loggedIn} authenticated={this.isLoggedIn()} path='/' myRender={(props) => <Homepage {...props} firebase={this.state.firebase} />} />
                      </Switch>
                    </div>
                    <Container maxWidth="xl" style={{ paddingTop: '1%' }}>
                      <Footer />
                    </Container>
                  </div>}
                </div>

              </MuiThemeProvider>
            </Context.UserContext.Provider>
          </Elements>
        </Router>
      </Provider>
    )
  }
}

export default App
