import LocalGenerationList from '../Home/components/file_order/LocalGenerationList'
import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import { withStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import { Context } from '../../constants'
import Box from '@material-ui/core/Box'
import GrayTextTypography from '../../components/common/GrayTextTypography'

class Standalone extends React.Component {

    static contextType = Context.UserContext;
    
    render() {
      const { classes } = this.props

      return (
        <Container className={classes.root} maxWidth="lg">
          <Box pt={15}>
            <CssBaseline />
            <Grid container>
              <Grid md={6} key={'left'} item>
                <Typography variant="h4" align="left">
                  <Box fontWeight="fontWeightBold" m={0}>
                    ID Generator
                  </Box>
                </Typography>
                <Box pt={1} pb={5}>
                  <GrayTextTypography style={{ paddingTop: '10' }} variant="subtitle2">
                    Set up complete data sets in customized order for free using our standalone generators!
                  </GrayTextTypography>
                </Box>
              </Grid>
              <Grid md={6} key={'right'} item>
                {this.context.subscribed && <LocalGenerationList /> }
              </Grid>
            </Grid>
          </Box >
        </Container>
      )
    }

}

Standalone.propTypes = {
  classes: PropTypes.object.isRequired,
}

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(0),
  },
})

Standalone.contextType = Context.UserContext

export default withStyles(styles)(Standalone)